#projects {
    padding-top: 5rem;

    @media screen and (max-width: 768px) {
        padding-top: 2rem;
    }

    h3{
        margin-bottom: 1rem;
    }
}


.m-project-img {
    width: 130px;
    border-radius: 3px;
    margin-top: 1.5rem;
    margin-right: 1rem;
    margin-left: 0rem;
}

.m-project-archive {
    display: flex;
    margin: 3rem 0rem;
    @media screen and (max-width: 768px) {
        margin: 2rem 0rem;
    }
    .m-block-year {
        width: 150px;

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    .m-project-archive-heading {
        font-weight: 600;
        color: var(--white-color);

        &:hover {
            color: var(--secondary-color);

            .right-arrow {
                color: var(--secondary-color);
                transform: translate(0.4rem, 0rem);
            }
        }

        &:after {
            content: "";
            display: block;
            position: relative;
            width: 0;
            height: 2px;
            margin-top: 5px;
            background: var(--secondary-color);
            transition: width 0.3s ease 0s, left 0.3s ease 0s;
        }

        &:hover:after {
            width: 102%;
        }
    }
}