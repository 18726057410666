#about {
    padding-top: 5rem;

    @media screen and (max-width: 768px) {
        padding-top:4rem;
    }

    h3{
        margin-bottom: 2.5rem;
    }
}

