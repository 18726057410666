body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {

  --cursorX: 50vw;
  --cursorY: 50vh;

  --font-base: "sans-serif";
  --primary-color: #E2E8F0;
  --secondary-color: #b39f3e;

  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #a8a8a8;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

:root:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  pointer-events: none;

  background: radial-gradient(
    circle 30vmax at var(--cursorX) var(--cursorY),
    rgba(179, 159, 62, 0) 0%,
    rgba(179, 159, 62, 0.2) 0%,
    rgba(0, 0, 0, 0.1) 100%
    )
}


ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

a {

  color: var(--gray-color);
  text-decoration: none;

}