.app {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    @media screen and (max-width: 768px) {
        display: block;
        gap: 0rem;

    }
}

.max-w-screen-xl {
    max-width: 1080px;
    padding-left: 6rem;
    padding-right: 6rem;

    @media screen and (max-width: 768px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.min-h-screen {
    min-height: 100vh;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.uppercase {
    text-transform: uppercase;
}


.p-text {
    line-height: 1.5rem;
}

.p-text2 {
    line-height: 1.5rem;
    font-size: 14px;

    a {
        font-weight: 700;

        &:hover {
            color: var(--secondary-color);
        }
    }
}

.items-center {
    align-items: center;
}

.flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap; //lex items should wrap onto multiple lines if there isn't enough space
}

.sticky {
    position: sticky;
    top: 0;

    @media screen and (max-width: 768px) {
        position: relative;
    }
}



.social-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1.5rem;
}

.up-right-arrow {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    margin-bottom: -0.2rem;
    margin-left: 0.2rem;
    transition: transform .15s ease-out;
}

.archive-project-icon {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    margin-bottom: -0.2rem;
    margin-left: 0.2rem;
    transition: transform .15s ease-out;
}

.right-arrow {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    margin-bottom: -0.2rem;
    margin-left: 0.2rem;
    transition: transform .1s ease-out;
}

.left-arrow {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    margin-bottom: -0.15rem;
    margin-right: 0.4rem;
    transition: transform 0.1s ease-out;
    transform: rotate(180deg);

    // &:hover {
    //     transform: rotate(180deg) translate(0.5rem, 0rem);
    // }
}

.skill-tag {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.15rem 0.75rem 0.25rem 0.75rem;

    line-height: 1.25rem;
    font-size: 14px;
    color: var(--secondary-color);
    background-color: rgba(131, 117, 47, 0.247);
    border-radius: 99px;
}



.text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
} 

.pr-2 {
    padding-right: 2rem;
}

.py-1{
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.font-semibold{
    font-weight: 600;
}

.align-top {
    vertical-align: top;
}

.border-slate-300\/10 {
    border-color: rgba(203,213,225,.1);
}

.border-b {
    border-bottom-width: 1px;
}

.desk-hidden{
    display: none;
    @media screen and (max-width: 768px) {
        display: block;
    }
}

.mobile-hidden{
    display: block;
    @media screen and (max-width: 768px) {
        display: none;
    }
}
