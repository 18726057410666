#experience {
    padding-top: 5rem;

    @media screen and (max-width: 768px) {
        padding-top: 3rem;
    }

    h3{
        margin-top: 1rem;
        margin-bottom: 1.5rem;
    }

    .m-resume {
        display: flex;
        margin: 3rem 0rem;


        @media screen and (max-width: 768px) {
            margin: 2rem 0rem;
        }
        .m-block-year {
            width: 150px;

            @media screen and (max-width: 768px) {
                display: none;
            }
        }

        .m-resume-heading {
            font-weight: 600;
            color: var(--white-color);

            &:hover {
                color: var(--secondary-color);

                .up-right-arrow {
                    color: var(--secondary-color);
                    transform: translate(0.25rem, -0.25rem);
                }
            }

            &:after {
                content: "";
                display: block;
                position: relative;
                width: 0;
                height: 2px;
                margin-top: 3px;
                background: var(--secondary-color);
                transition: width 0.3s ease 0s, left 0.3s ease 0s;
            }

            &:hover:after {
                width: 101%;
            }
        }
    }
}